<template>
  <sgv-table
    :rKey="rKey"
    :items="devices"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">

    <template v-slot="{item, hidden}">
      <tr class="">
        <td
          v-if="hidden.code"
          @click="toDetail(item.id)"
          class="pointer"
          :class="{'text-danger': !item.isActive}">
          {{ item.code }}
        </td>
        <td v-if="hidden.name">{{ item.name }}</td>
      </tr>
    </template>

    <template v-slot:action>
      <button
        v-if="$auth.hasRole(`device:${deviceType}:add`)"
        type="button"
        class="btn btn-link text-success"
        @click="toDetail(0)">
        เพิ่ม
      </button>
    </template>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_DEVICE } from './graph/device'

export default {
  mixins: [retainMixin],
  props: {
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      detailView: `Device${this.$form.capitalize(this.deviceType)}Detail`,
      rKey: `Device${this.$form.capitalize(this.deviceType)}ListCategory`,
      rFields: ['filter', 'options'],
      headers: [
        {text: 'รหัส', value: 'code', sort: true, filter: true},
        {text: 'ชื่อ', value: 'name', sort: true, filter: true}
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      devices: []
    }
  },
  apollo: {
    devices: {
      query () {
        return LIST_DEVICE(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          deviceType: this.deviceType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only',
    }
  },
  methods: {
    getFilter (v) {
      return {
        ...v,
        params: v.params || {}
      }
    },
    toDetail (id) {
      this.$router.push({
        name: this.detailView,
        params: {deviceId: id}
      })
    }
  },
  watch: {
    'filter': 'setRetaining',
    'options': 'setRetaining'
  }
}
</script>

<style lang="css" scoped>
</style>
