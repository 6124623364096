import gql from 'graphql-tag'


const listResponse = `
  id type code name isActive
`

export const LIST_DEVICE = (templateType) => gql`query LIST_DEVICE ($deviceType: String!, $q: FilterInput) {
  devices: list${templateType} (deviceType: $deviceType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type code name isActive
  printerEmail deviceEmailId
  clientId clientSecret 
  apiKey redirectUrl
  accessToken refreshToken expiresIn
  printerId
`

export const DETAIL_DEVICE = (templateType) => gql`query DETAIL_DEVICE ($deviceType: String!, $deviceId: Int!) {
  device: detail${templateType} (deviceType: $deviceType, deviceId: $deviceId) {${detailResponse}}
}`

export const CREATE_DEVICE = (templateType) => gql`mutation CREATE_DEVICE ($deviceType: String!, $input: ${templateType}Input!) {
  createDevice: create${templateType} (deviceType: $deviceType, input: $input) {${detailResponse}}
}`

export const UPDATE_DEVICE = (templateType) => gql`mutation UPDATE_DEVICE ($deviceType: String!, $deviceId: Int!, $input: ${templateType}Input!) {
  updateDevice: update${templateType} (deviceType: $deviceType, deviceId: $deviceId, input: $input) {${detailResponse}}
}`

export const UPDATE_DEVICE_TOKEN = (templateType) => gql`mutation UPDATE_DEVICE_TOKEN ($deviceType: String!, $deviceId: Int!, $token: String!) {
  deviceId: update${templateType}Token (deviceType: $deviceType, deviceId: $deviceId, token: $token)
}`

export const DESTROY_DEVICE = (templateType) => gql`mutation DESTROY_DEVICE ($deviceType: String!, $deviceId: Int!) {
  destroyDevice: destroy${templateType} (deviceType: $deviceType, deviceId: $deviceId) {id}
}`

export const PRINT_DEVICE = (templateType) => gql`mutation PRINT_DEVICE ($deviceType: String!, $deviceId: Int!, $url: String!) {
  result: print${templateType} (deviceType: $deviceType, deviceId: $deviceId, url: $url)
}`

export const DP_EMAIL = (templateType) => gql`query DP_EMAIL ($deviceType: String!, $q: FilterInput) {
  items: list${templateType}DropdownEmail (deviceType: $deviceType, q: $q) {
    id type code name
  }
}`

export const DETAIL_DEVICE_PRINTER_INTO = (templateType) => gql`query DETAIL_DEVICE_PRINTER_INTO ($deviceType: String!, $deviceId: Int!) {
  info: detail${templateType}PrinterInfo (deviceType: $deviceType, deviceId: $deviceId)
}`